import Vue from 'vue';
import VueRecaptcha from 'vue-recaptcha';
import {get, post} from '@/services/api';
import {dealers, signup} from '../../services/endpoint.json';
import {VueTelInput} from 'vue-tel-input';
import CodeInput from "vue-verification-code-input";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'Register',
  mixins: [show_alert_mixin],
  components: {
    'vue-recaptcha': VueRecaptcha,
    VueTelInput,
    CodeInput
  },
  data: () => ({
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    phoneNumber: '',
    country: '',
    confirmation: '',
    dealer: null,
    optionsDealers: [],
    optionsSubDealers: [],
    terms: false,
    viewError: true,
    controlViewDealer: false,
    subdealer: null,
    robot: false,
    validNumber: false,
    countryAlpha2Code: null,
    showValidatePhone: false,
    userRegistered: null,
    isValidation: false,
    message: {
      class: 'text-success',
      text: 'Please enter the code from the SMS message we just sent to your phone'
    },
    secondsCount: {
      value: 45,
      interval: null,
    },
    arrayCountries: [],
    selectedCountry: null,
    countryCombo: null,
    license: false,
  }),
  created() {
    this.getCountries().then()
  },
  methods: {
    setCountryAndPhone(value, object) {
      this.validNumber = typeof object.valid === 'boolean' && object.valid;
      if (object.country !== undefined) {
        this.phoneNumber = object.number;
        this.countryAlpha2Code = object.country.iso2;
        this.country = object.country.name;
      }
    },
    async getCountries() {
      const {data} = await get('auth/countries', null, false)
      this.arrayCountries = data;
    },
    setCountry(object) {
      this.countryAlpha2Code = object.iso2;
      this.getDealers().then();
    },
    setCountryCombo() {
      this.selectedCountry = this.arrayCountries.find(x => x.id == this.countryCombo)
      this.countryAlpha2Code = this.selectedCountry.alpha2Code;
      this.getDealers().then();
    },
    async register() {
      this.viewError = this.validateTerm();
      if (this.validNumber && this.terms && this.license && this.robot && this.selectedCountry !== null) {
        this.isValidation = true;
        const {data} = await post(`auth/${signup}`, {
          name: this.name,
          lastName: this.lastName,
          email: this.email.toLowerCase(),
          password: this.password,
          phone: this.phoneNumber,
          country: this.selectedCountry.name,
          dealerId: this.dealer,
          countryAlpha2Code: this.selectedCountry.alpha2Code,
        });
        this.isValidation = false;
        if (Number(data.statusCode) === Number(200)) {
          await this.$swal({
            title: 'Welcome to TVS',
            text: `We sent an email to ${this.email.toLowerCase()}, please verify and confirm your email.`,
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: "#d14343",
            cancelButtonColor: "#000000",
            confirmButtonText: "OK",
          });
          await this.$router.push('login')
        } else {
          this.showAlertNotification(data.error, 'error')
        }
      }
    },
    clearData() {
      this.name = '';
      this.lastName = '';
      this.email = '';
      this.password = '';
      this.phone = '';
      this.country = '';
      this.confirmation = '';
      this.selectedCountry = null
    },
    validateTerm() {
      return (this.terms === true && this.license);
    },
    async getDealers() {
      let {data} = await get(dealers, this.countryAlpha2Code, true);
      if (Number(data.statusCode) === Number(200)) {
        const filtered = data.message.filter(x => x.value !== 2 && x.value !== 26)
        this.optionsDealers = [{value: null, text: 'Select'}].concat(filtered);
      }
      this.optionsSubDealers = [
        {value: null, text: 'Select'},
        {value: 'NO', text: 'NO'},
        {value: 'YES', text: 'YES'},
      ];
    },
    viewDealer(event) {
      this.dealer = null
      if (event !== null)
        this.controlViewDealer = (event.toString() === 'YES');
    },
    selectDealer(event) {
      this.dealer = event;
    },
    onVerify(response) {
      if (response) this.robot = true;
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    redirect() {
      window.open('https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+terms+and+condition.pdf', '_blank')
    },
    redirectLicense() {
      window.open('https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+license+agreements.pdf', '_blank')
    },
    async onComplete(code) {
      this.isValidation = true;
      post('auth/verify-phone-code', {id: this.userRegistered.id, code: code}).then(({data}) => {
        if (data) {
          this.showAlert('Phone successfully verified', 'success')
          this.$router.push('login');
        } else {
          this.message = {
            class: 'text-danger',
            text: 'Invalid code, please try again or "Resend verification code"'
          }
        }
      }).finally(() => this.isValidation = false);
    },
    reSendCode() {
      post(`auth/re-send-sms`, {id: this.userRegistered.id, phone: this.phoneNumber})
        .then(({data}) => {
          this.userRegistered = data;
          this.startCounter();
        })
    },
    startCounter() {
      this.secondsCount.value = 45;
      if (this.secondsCount.interval) {
        clearInterval(this.secondsCount.interval)
      }
      this.secondsCount.interval = setInterval(() => {
        this.secondsCount.value--;
      }, 1000)
    },
  },
  destroyed() {
    clearInterval(this.secondsCount.interval)
  }
});
